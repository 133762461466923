import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@dashboard/common/ui/dialog";
import {
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectRoot,
} from "@dashboard/common/ui/select";
import { ReactNode } from "@tanstack/react-router";
import { useConversation } from "../-hooks/useConversation.hook";
import { RouterOutput } from "@be/exports";
import { ExtractUnionMember } from "@dashboard/utils/types";
import { useState } from "react";
import { toast } from "sonner";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import { MarkdownContent } from "@dashboard/common/MarkdownContent";

type BotMessage = ExtractUnionMember<
  RouterOutput["dashboard"]["conversations"]["get"]["messages"][0],
  "sender",
  "bot"
>;

export function DebugModal({ children }: { children: ReactNode }) {
  const conversation = useConversation();

  const botMessages = conversation.messages.filter(
    (m): m is BotMessage => m.sender === "bot",
  );

  const [selectedMessage, setSelectedMessage] = useState<BotMessage | null>(
    null,
  );

  const copyToClipboard = (value: string | null | undefined) => {
    if (!value) return;
    void navigator.clipboard.writeText(value);
    toast("Value copied to clipboard");
  };

  return (
    <Dialog onOpenChange={() => setSelectedMessage(null)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-h-[80vh] overflow-scroll sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Conversation debugging info</DialogTitle>
          <DialogDescription>
            Use the dropdown below to get the details of a bot response
          </DialogDescription>
        </DialogHeader>
        <div>
          <SelectRoot
            onValueChange={(id) => {
              setSelectedMessage(botMessages.find((m) => m.id === id) ?? null);
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select the response" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {botMessages.map((m, idx) => (
                  <SelectItem key={idx} value={m.id}>
                    Response {idx + 1}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </SelectRoot>
          {selectedMessage && (
            <div className="mt-4">
              {!selectedMessage.generationDetails ? (
                <p>This bot response was not generated by AI</p>
              ) : (
                <>
                  {/* Content */}
                  <h2 className="font-semibold">Content</h2>
                  <MarkdownContent
                    content={selectedMessage.text}
                    className="mt-2"
                  />

                  {/* System prompt */}
                  <h2 className="mt-4 font-semibold">System prompt</h2>
                  <div className="mt-4">
                    <Button
                      variant="outline"
                      onClick={() =>
                        copyToClipboard(
                          selectedMessage.generationDetails?.systemPrompt,
                        )
                      }
                    >
                      Copy system prompt
                    </Button>
                  </div>

                  {/* Threat reasoning */}
                  {selectedMessage.generationDetails.threatReasoning && (
                    <div>
                      <h2 className="mt-4 font-semibold">Threat reasoning</h2>
                      <MarkdownContent
                        content={
                          selectedMessage.generationDetails.threatReasoning
                        }
                        className="mt-2"
                      />
                    </div>
                  )}

                  {/* Workflow reasoning */}
                  {selectedMessage.generationDetails.workflowReasoning && (
                    <div>
                      <h2 className="mt-4 font-semibold">Workflow reasoning</h2>
                      <MarkdownContent
                        content={
                          selectedMessage.generationDetails.workflowReasoning
                        }
                        className="mt-2"
                      />
                    </div>
                  )}

                  {/* Function reasoning */}
                  {selectedMessage.generationDetails.functionCalls.map(
                    (call, idx) =>
                      call.functionReasoning && (
                        <div key={idx}>
                          <h2 className="mt-4 font-semibold">
                            Function reasoning ({call.functionName})
                          </h2>
                          <MarkdownContent
                            content={call.functionReasoning}
                            className="mt-2"
                          />
                        </div>
                      ),
                  )}

                  {/* Function calls */}
                  {selectedMessage.generationDetails.functionCalls.length >
                    0 && (
                    <div>
                      <h2 className="mt-4 font-semibold">Function calls</h2>
                      <Accordion
                        type="single"
                        collapsible
                        className="mt-2 w-full"
                      >
                        {selectedMessage.generationDetails.functionCalls.map(
                          (call, idx) => (
                            <AccordionItem key={idx} value={idx.toString()}>
                              <AccordionTrigger>
                                {call.functionName}
                              </AccordionTrigger>
                              <AccordionContent>
                                <div className="flex flex-row gap-4">
                                  <Button
                                    onClick={() =>
                                      copyToClipboard(call.arguments)
                                    }
                                    variant="outline"
                                  >
                                    Copy arguments
                                  </Button>
                                  <Button
                                    onClick={() => copyToClipboard(call.result)}
                                    variant="outline"
                                  >
                                    Copy result
                                  </Button>
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          ),
                        )}
                      </Accordion>
                    </div>
                  )}

                  {/* Articles used */}
                  {selectedMessage.generationDetails.chunks.length > 0 && (
                    <div>
                      <h2 className="mt-4 font-semibold">Chunks used</h2>
                      <Accordion
                        type="single"
                        collapsible
                        className="mt-2 w-full"
                      >
                        {selectedMessage.generationDetails.chunks.map(
                          (chunk, idx) => (
                            <AccordionItem key={idx} value={idx.toString()}>
                              <AccordionTrigger>
                                {chunk.contextChunkId}
                              </AccordionTrigger>
                              <AccordionContent>
                                {chunk.content}
                              </AccordionContent>
                            </AccordionItem>
                          ),
                        )}
                      </Accordion>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

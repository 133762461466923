import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { DisplayPassedTimeWithTooltip } from "@dashboard/common/DisplayPassedTimeWithTooltip";
import { useConversation } from "../-hooks/useConversation.hook";
import { useMemo } from "react";
import { Route } from "..";
import botIcon from "@dashboard/assets/bot.svg";

type Message = ReturnType<typeof useConversation>["messages"][number];
type ExternalMessage = Extract<
  Message,
  { sender: "external" } | { sender: "agent" } | { sender: "bot" }
>;

// TODO: generalize / combine Message & ExternalMessage components

export function ExternalMessage({ message }: { message: ExternalMessage }) {
  const isExternal = message.sender === "external";

  const filesMd = useMemo(() => {
    return message.files
      .map((file) => {
        if (file.contentType.startsWith("image")) {
          return `![${file.name}](${file.url})`;
        } else {
          return `[${file.name}](${file.url})`;
        }
      })
      .join("\n\n");
  }, [message]);

  const { useTranslation } = Route.useSearch();
  const { user } = Route.useRouteContext();

  let translation: string | undefined;

  if (useTranslation === undefined || useTranslation) {
    translation = message.translations.find(
      (t) => t.translatedTo === user.translationLanguage,
    )?.translation;
  }

  const text = translation ?? message.text;

  const content = `${text}\n\n${filesMd}`;

  return (
    <div
      className={`flex ${
        !isExternal ? "flex-row-reverse" : "flex-row"
      } gap-x-2 p-2`}
    >
      {/* avatar */}
      <div className="flex flex-col-reverse pb-2">
        <Avatar message={message} />
      </div>

      {/* message */}

      <div
        className={`max-w-md rounded-lg px-4 pb-2 pt-3 xl:max-w-lg ${
          !isExternal
            ? "bg-[#DCE1F9] hover:bg-[#CED6F7]"
            : "bg-gray-lightest hover:bg-gray-light"
        }`}
      >
        <MarkdownContent content={content} className="break-words text-sm" />
        <div className="mt-1 flex flex-row items-end">
          <span className="text-gray text-[13px]">
            <DisplayPassedTimeWithTooltip
              date={message.createdAt}
              side="bottom"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function Avatar({ message }: { message: ExternalMessage }) {
  if (message.sender === "external") {
    return <ExternalAvatar />;
  } else if (message.sender === "agent") {
    return <AgentAvatar name={message.agentName} />;
  } else {
    return <BotAvatar />;
  }
}

function ExternalAvatar() {
  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-[#ec4662]">
      <div className="h-0.5 w-[40%] rounded-full bg-white"></div>
    </div>
  );
}

function AgentAvatar({ name }: { name: string }) {
  const initials = name
    .split(" ")
    .map((s) => s[0].toUpperCase())
    .join("")
    .slice(0, 2);

  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-black text-xs font-medium text-white">
      {initials}
    </div>
  );
}

function BotAvatar() {
  return (
    <img
      alt="logo"
      className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center"
      src={botIcon}
    />
  );
}

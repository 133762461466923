import { Route } from "../../onboarding";

export const useCreateWebsites = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const createMutation = trpc.dashboard.scraper.createMany.useMutation({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      console.error(error);
      if (onError) {
        onError();
      }
    },
  });

  return {
    createWebsites: (data: { websites: string[] }) => {
      createMutation.mutate({
        businessSlug: organizationSlug,
        websites: data.websites,
      });
    },
  };
};

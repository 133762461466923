import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@dashboard/common/ui/card";
import { Plan } from "./types";

interface CurrentPlanCardProps {
  currentPlan: Plan | undefined;
}

export function CurrentPlanCard({ currentPlan }: CurrentPlanCardProps) {
  if (!currentPlan) {
    return (
      <Card className="border-gray-light flex flex-col items-start justify-start gap-2 rounded-lg border bg-white">
        <CardHeader>
          <CardTitle className="text-lg font-medium">No Active Plan</CardTitle>
          <CardDescription>Select a plan below to get started</CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card className="border-blue-light flex flex-col items-start justify-start gap-2 rounded-lg border bg-white">
      <CardHeader>
        <div className="flex w-full items-center gap-2">
          <currentPlan.icon className="text-primary h-5 w-5" />
          <CardTitle className="text-lg font-medium text-gray-800">
            Current Plan
          </CardTitle>
        </div>
        <CardDescription className="text-left text-gray-600">
          Your current subscription plan and usage
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 md:grid-cols-2">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <h3 className="font-semibold">{currentPlan.name}</h3>
          </div>
          <p className="text-2xl font-bold">${currentPlan.price}/month</p>
        </div>
        <div className="space-y-1 text-sm">
          <p>
            {currentPlan.maxConversations.toLocaleString()} conversations
            included
          </p>
          <p className="text-muted-foreground">
            ${currentPlan.overageFee} per 100 additional conversations
          </p>
        </div>
      </CardContent>
    </Card>
  );
}

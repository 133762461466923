import { Outlet, createRootRoute } from "@tanstack/react-router";
import { httpBatchLink } from "@trpc/client";
import type { AppRouter } from "@be/exports";
import { Toaster } from "@dashboard/common/ui/sonner";
import { env } from "@dashboard/env";
import { TooltipProvider } from "@dashboard/common/ui/tooltip";
import { createTRPCQueryUtils, createTRPCReact } from "@trpc/react-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense } from "react";
const trpc = createTRPCReact<AppRouter>();
const queryClient = new QueryClient();

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

export const Route = createRootRoute({
  notFoundComponent: () => <p>Page not found</p>,
  beforeLoad: () => {
    const trpcClient = trpc.createClient({
      links: [
        httpBatchLink({
          url: env.DASHBOARD_TRPC_HTTP_URL,
          fetch: (url, options) => {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    });
    const trpcClientUtils = createTRPCQueryUtils({
      queryClient,
      client: trpcClient,
    });

    return {
      trpc,
      queryClient,
      trpcClient,
      trpcClientUtils,
    };
  },
  component: RootLayout,
});

function RootLayout() {
  const { trpc, queryClient, trpcClient } = Route.useRouteContext();

  return (
    <>
      <TooltipProvider delayDuration={0}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </trpc.Provider>
      </TooltipProvider>
      <Toaster position="top-center" />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </>
  );
}

import { createFileRoute } from "@tanstack/react-router";
import zendeskLogo from "@dashboard/assets/zendesk.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { env } from "@dashboard/env";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useOrganization } from "../-hooks/useOrganization";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { CircleHelp } from "lucide-react";

const formSchema = z.object({
  subdomain: z
    .string({
      required_error: "Subdomain is required",
      invalid_type_error: "Subdomain is required",
    })
    .min(1, "Subdomain is required"),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/help-desk/zendesk",
)({
  component: ZendeskPage,
});

function ZendeskPage() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subdomain: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    window.location.href = `${env.DASHBOARD_BASE_API_URL}/api/zendesk/generate-oauth-link?customerSubdomain=${values.subdomain}`;
  };

  const organization = useOrganization();
  const stepCompleted = organization.onboardingStatus !== "HELP-DESK";

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect your Zendesk account
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Octocom will connect with your Zendesk account to route unhandled
        customer support tickets.
      </h2>
      <div className="w-full max-w-3xl py-4">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="subdomain"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="h-10 border-[#041627]"
                        placeholder="Enter your Zendesk subdomain"
                        {...field}
                      />
                      <Tooltip
                        disableHoverableContent={true}
                        triggerAsChild
                        side="bottom"
                        content={
                          <p className="max-w-96 text-sm text-gray-800">
                            Your Zendesk subdomain is the part of the URL that
                            comes before .zendesk.com. <br /> <br /> e.g. if
                            your URL is: <br />
                            <span className="font-mono">
                              https://my-zendesk-account.zendesk.com
                            </span>
                            <br />
                            <br />
                            Your subdomain is: <br />
                            <span className="font-mono font-semibold">
                              my-zendesk-account
                            </span>
                          </p>
                        }
                        trigger={
                          <CircleHelp
                            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
                            strokeWidth={1.5}
                          />
                        }
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="h-10 w-full justify-start border-[#041627]"
            >
              <img
                src={zendeskLogo}
                alt="Zendesk Logo"
                className="mr-4 h-6 w-6"
              />
              Connect Zendesk
            </Button>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/help-desk"
          nextPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}

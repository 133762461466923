import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { Select } from "@dashboard/common/ui/select";
import { z } from "zod";
import { Accordion } from "@dashboard/common/ui/accordion";
import { AccordionItemComponent } from "./-components/AccordionItemComponent";
import { TestYourBot } from "./-components/TestYourBot";
import { AddKnowledge } from "./-components/AddKnowledge";
import { Subscription } from "./-components/Subscription";
import { SetupChannels } from "./-components/SetupChannels";
import { BookText, Bot, CreditCard, Split, Users } from "lucide-react";
import { MessageCircle } from "lucide-react";
import { AddTeamMembers } from "./-components/AddTeamMembers";
import { ImplementFlows } from "./-components/ImplementFlows";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/setup/",
)({
  validateSearch: z.object({
    businessSlug: z.string().optional(),
  }),
  beforeLoad: ({
    context: { businesses },
    search: { businessSlug },
    params: { organizationSlug },
  }) => {
    if (!businessSlug) {
      const business = businesses[0];
      return { business };
    } else {
      const business = businesses.find(
        (business) => business.slug === businessSlug,
      );

      if (!business) {
        throw redirect({
          to: `/organization/$organizationSlug/setup`,
          params: { organizationSlug },
          search: { businessSlug: undefined },
        });
      }

      return { business };
    }
  },
  loader: async ({ context: { trpcClientUtils, businesses } }) => {
    await Promise.all(
      businesses.map((business) =>
        Promise.all([
          trpcClientUtils.dashboard.webChat.getConfig.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.business.getOrThrow.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.integrations.getInstalledIntegrations.ensureData(
            {
              businessSlug: business.slug,
            },
          ),
        ]),
      ),
    );
  },
  component: SetupPage,
});

function SetupPage() {
  const { trpc, business, businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const navigate = useNavigate();

  const [webChatConfig] = trpc.dashboard.webChat.getConfig.useSuspenseQuery({
    businessSlug: business.slug,
  });

  const [{ completedOnboardingSteps }] =
    trpc.dashboard.business.getOrThrow.useSuspenseQuery({
      businessSlug: business.slug,
    });

  useEffect(() => {
    const existingScript = document.getElementById("octocom-script");
    if (existingScript) {
      document.head.removeChild(existingScript);
    }

    const script = document.createElement("script");
    script.src = "https://www.octocom.ai/chatbot/bundle.js";
    script.id = "octocom-script";
    script.setAttribute("data-config-id", webChatConfig.id);
    script.setAttribute("data-open-window", "true");
    script.referrerPolicy = "no-referrer-when-downgrade";
    document.head.appendChild(script);

    return () => {
      const scriptToRemove = document.getElementById("octocom-script");
      if (scriptToRemove) {
        document.head.removeChild(scriptToRemove);
      }
    };
  }, [webChatConfig.id]);

  const options = businesses.map((business) => ({
    label: business.name,
    value: business.slug,
  }));

  const setBusiness = (value: string) => {
    void navigate({
      search: (prev) => ({ ...prev, businessSlug: value }),
    });
  };

  const hasSubscription = completedOnboardingSteps.includes("subscription");

  const setupSteps = [
    {
      value: "test-your-bot",
      title: "Test Your Bot",
      subtitle: "Test your bot to see how it performs with customer queries.",
      icon: Bot,
      component: (
        <TestYourBot completed={completedOnboardingSteps.includes("test")} />
      ),
      completed: completedOnboardingSteps.includes("test"),
      disabled: false,
    },
    {
      value: "add-knowledge",
      title: "Add Knowledge Base",
      subtitle:
        "Train your bot with your business information and documentation for more accurate responses.",
      icon: BookText,
      component: (
        <AddKnowledge
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("knowledge")}
        />
      ),
      completed: completedOnboardingSteps.includes("knowledge"),
      disabled: false,
    },
    {
      value: "subscription",
      title: "Choose a Plan",
      subtitle:
        "Select the perfect plan that matches your business needs and expected usage.",
      icon: CreditCard,
      component: (
        <Subscription
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
        />
      ),
      completed: completedOnboardingSteps.includes("subscription"),
      disabled: false,
    },
    {
      value: "setup-channels",
      title: "Set up Communication Channels",
      subtitle:
        "Connect your bot to various platforms where your customers can interact with it.",
      icon: MessageCircle,
      component: (
        <SetupChannels
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("channels")}
        />
      ),
      completed: completedOnboardingSteps.includes("channels"),
      disabled: !hasSubscription,
    },
    {
      value: "team",
      title: "Add Team Members",
      subtitle:
        "Invite and manage team members to collaborate on customer support and bot training.",
      icon: Users,
      component: (
        <AddTeamMembers
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("team")}
        />
      ),
      completed: completedOnboardingSteps.includes("team"),
      disabled: !hasSubscription,
    },
    {
      value: "flows",
      title: "Implement Customer Support Flows",
      subtitle:
        "Create automated workflows and response patterns to streamline your customer support process.",
      icon: Split,
      component: (
        <ImplementFlows
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("flows")}
        />
      ),
      completed: completedOnboardingSteps.includes("flows"),
      disabled: !hasSubscription,
    },
  ];

  return (
    <div className="flex h-full w-full flex-col">
      <div className="border-b-gray-light flex w-full items-center justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium">Setup</h1>
        {businesses.length > 1 && (
          <Select
            variant="dropdown"
            value={business.slug}
            onChange={setBusiness}
            options={options}
            placeholder="Select a business"
          />
        )}
      </div>
      <div className="flex w-full flex-1 overflow-hidden">
        <div className="flex h-full w-full justify-center overflow-auto px-10 py-6">
          <div className="flex w-full flex-col gap-6">
            <div className="flex w-full flex-col gap-2">
              <h2 className="text-2xl font-semibold">Welcome to Octocom</h2>
              <p className="text-justify text-gray-800">
                We&apos;re excited to have you onboard! Let&apos;s get your
                Octocom account ready.
              </p>
            </div>
            <Accordion
              type="single"
              collapsible
              className="flex w-full flex-col gap-4 pb-10"
            >
              {setupSteps.map((step) => (
                <AccordionItemComponent
                  key={step.value}
                  value={step.value}
                  title={step.title}
                  subtitle={step.subtitle}
                  icon={step.icon}
                  completed={step.completed}
                  disabled={step.disabled}
                >
                  {step.component}
                </AccordionItemComponent>
              ))}
            </Accordion>
          </div>
        </div>
        <div className="border-l-gray-light flex h-full w-[50%] items-center justify-center overflow-auto border-l p-4">
          <div className="h-full w-full" id="octocom-container" />
        </div>
      </div>
    </div>
  );
}

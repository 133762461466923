import React, { ElementType } from "react";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { Check, Lock } from "lucide-react";

interface AccordionItemComponentProps {
  value: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
  icon: ElementType;
  completed: boolean;
  disabled: boolean;
}

export function AccordionItemComponent({
  value,
  title,
  subtitle,
  children,
  icon: Icon,
  completed,
  disabled,
}: AccordionItemComponentProps) {
  return (
    <AccordionItem
      value={value}
      disabled={disabled}
      className={`rounded-lg border ${
        disabled
          ? "border-gray-200 bg-gray-50 opacity-60"
          : "border-[#041627] hover:bg-gray-50"
      }`}
    >
      <AccordionTrigger className="flex items-center justify-between px-4 py-3">
        <div className="flex flex-1 items-center gap-4">
          <div
            className={`flex h-12 w-12 items-center justify-center rounded-lg ${
              completed
                ? "bg-black"
                : disabled
                ? "border border-gray-300 bg-gray-100"
                : "border border-gray-400"
            }`}
          >
            {completed ? (
              <Check className="h-8 w-8 text-white" strokeWidth={2} />
            ) : disabled ? (
              <Lock className="h-8 w-8 text-gray-400" strokeWidth={1.5} />
            ) : (
              <Icon className="h-8 w-8 text-black" strokeWidth={1.5} />
            )}
          </div>
          <div className="flex flex-col items-start gap-1">
            <span
              className={`text-lg font-medium ${
                disabled ? "text-gray-500" : "text-black"
              }`}
            >
              {title}
            </span>
            <span className="text-justify text-sm text-gray-500">
              {subtitle}
            </span>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent className="p-4">{children}</AccordionContent>
    </AccordionItem>
  );
}

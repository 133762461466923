import { useMemo } from "react";
import { useConversation } from "../-hooks/useConversation.hook";
import { Route } from "..";
import botIcon from "@dashboard/assets/bot.svg";
import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { DisplayPassedTimeWithTooltip } from "@dashboard/common/DisplayPassedTimeWithTooltip";
import { differenceInDays } from "date-fns";
import { exhaustive } from "@dashboard/utils/other";

type Message = ReturnType<typeof useConversation>["messages"][number];
type NonExternalMessage = Exclude<Message, { sender: "external" }>;

export function Message({ message }: { message: NonExternalMessage }) {
  const { organization, user } = Route.useRouteContext();

  const isCustomer = message.sender === "customer";

  const filesMd = useMemo(() => {
    const ageInDays = differenceInDays(Date.now(), new Date(message.createdAt));
    if (organization.slug === "gls-pharmaceuticals" && ageInDays > 3) {
      // This is a hacky way to prevent showing images in old messages for GLS.
      // We don't want to actually delete the images, just hide them.
      // We will generalize this later.
      return "";
    }

    return message.files
      .map((file) => {
        if (file.contentType.startsWith("image")) {
          return `![${file.name}](${file.url})`;
        } else {
          return `[${file.name}](${file.url})`;
        }
      })
      .join("\n\n");
  }, [message, organization.slug]);

  const { useTranslation } = Route.useSearch();

  let translation: string | undefined;

  if (useTranslation === undefined || useTranslation) {
    translation = message.translations.find(
      (t) => t.translatedTo === user.translationLanguage,
    )?.translation;
  }

  const text = translation ?? message.text;

  const content = `${text}\n\n${filesMd}`;

  return (
    <div
      className={`flex ${
        !isCustomer ? "flex-row-reverse" : "flex-row"
      } gap-x-2 p-2`}
    >
      {/* avatar */}
      <div className="flex flex-col-reverse pb-2">
        <Avatar message={message} />
      </div>

      {/* message */}

      <div
        className={`max-w-md rounded-lg px-4 pb-2 pt-3 xl:max-w-lg ${
          !isCustomer
            ? "bg-[#DCE1F9] hover:bg-[#CED6F7]"
            : "bg-gray-lightest hover:bg-gray-light"
        }`}
      >
        <MarkdownContent content={content} className="break-words text-sm" />
        <div className="mt-1 flex flex-row items-end">
          <span className="text-gray text-[13px]">
            <DisplayPassedTimeWithTooltip
              date={message.createdAt}
              side="bottom"
              extraText={`via ${message.channel}`}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

function Avatar({ message }: { message: NonExternalMessage }) {
  if (message.sender === "customer") {
    return <CustomerAvatar />;
  } else if (message.sender === "bot") {
    return <BotAvatar />;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (message.sender === "agent") {
    return <AgentAvatar name={message.agentName} />;
  } else {
    exhaustive(message);
  }
}

function CustomerAvatar() {
  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-[#ec4662]">
      <div className="h-0.5 w-[40%] rounded-full bg-white"></div>
    </div>
  );
}

function BotAvatar() {
  return (
    <img
      alt="logo"
      className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center"
      src={botIcon}
    />
  );
}

function AgentAvatar({ name }: { name: string }) {
  const initials = name
    .split(" ")
    .map((s) => s[0].toUpperCase())
    .join("")
    .slice(0, 2);

  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-black text-xs font-medium text-white">
      {initials}
    </div>
  );
}

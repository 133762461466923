import { Check } from "lucide-react";
import { Button } from "@dashboard/common/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@dashboard/common/ui/card";
import { Plan, ShopifySubscription } from "./types";

interface PlanSelectionProps {
  plans: Plan[];
  selectedPlan: ShopifySubscription;
  currentPlan?: ShopifySubscription;
  onPlanChange: (plan: ShopifySubscription) => void;
  onConfirm: () => void;
}

export function PlanSelection({
  plans,
  selectedPlan,
  currentPlan,
  onPlanChange,
  onConfirm,
}: PlanSelectionProps) {
  return (
    <div className="space-y-4">
      <div className="text-center">
        <h2 className="text-2xl font-bold">Choose a Plan</h2>
        <p className="text-muted-foreground">
          Select a plan that best fits your needs
        </p>
      </div>
      <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
        {plans.map((plan) => {
          const isSelected = selectedPlan === plan.id;

          return (
            <Card
              key={plan.id}
              onClick={() => onPlanChange(plan.id)}
              className={`border-blue-light hover:bg-blue-light hover:border-blue flex h-full cursor-pointer flex-col gap-2 rounded-lg border bg-white transition-all duration-200 ease-in-out hover:shadow-lg ${
                isSelected ? "bg-blue-light border-blue shadow-lg" : ""
              }`}
            >
              <CardHeader>
                <div className="flex items-center justify-between">
                  <div
                    className={`flex items-center gap-2 ${
                      isSelected ? "text-blue" : ""
                    }`}
                  >
                    <plan.icon className="h-6 w-6" />
                    <CardTitle className="text-xl">{plan.name}</CardTitle>
                  </div>
                  {isSelected && <Check className="text-blue h-5 w-5" />}
                </div>
                <CardDescription className="text-2xl font-bold">
                  ${plan.price}
                  <span className="text-muted-foreground text-sm font-normal">
                    /month
                  </span>
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-2 text-sm">
                  <p>
                    <span className="font-medium">
                      {plan.maxConversations.toLocaleString()}
                    </span>{" "}
                    conversations included
                  </p>
                  <p className="text-muted-foreground">
                    ${plan.overageFee} per 100 additional conversations after{" "}
                    {plan.maxConversations.toLocaleString()} conversations
                  </p>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <div className="flex justify-end">
        <Button
          className="w-full"
          variant="secondary"
          onClick={onConfirm}
          disabled={selectedPlan === currentPlan}
        >
          Confirm Change
        </Button>
      </div>
    </div>
  );
}

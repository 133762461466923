import { Route } from "../../onboarding";

export const useCreateArticle = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const createMutation = trpc.dashboard.articles.create.useMutation({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      console.error(error);
      if (onError) {
        onError();
      }
    },
  });

  return {
    createArticle: (data: { title: string; content: string }) => {
      createMutation.mutate({
        businessSlug: organizationSlug,
        title: data.title,
        content: data.content,
      });
    },
  };
};

import { createFileRoute } from "@tanstack/react-router";
import { Mail } from "lucide-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useOrganization } from "../-hooks/useOrganization";
import { useCreateEmailExternalTicketConfig } from "../-hooks/useCreateEmailExternalTicketConfig";

const formSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
      invalid_type_error: "Email is required",
    })
    .email("Invalid email address")
    .min(1, "Email is required"),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/help-desk/email",
)({
  component: EmailSetupPage,
});

function EmailSetupPage() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const { createConfig } = useCreateEmailExternalTicketConfig();

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    createConfig({
      email: values.email,
    });
  };

  const organization = useOrganization();
  const stepCompleted = organization.onboardingStatus !== "HELP-DESK";

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Connect your email account
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Octocom will route unhandled customer support tickets to the email
        address you provide.
      </h2>
      <div className="w-full max-w-3xl py-4">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className="h-10 border-[#041627]"
                      placeholder="Enter your email address"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="h-10 w-full justify-start border-[#041627]"
            >
              <Mail className="mr-4 h-6 w-6" strokeWidth={1.5} />
              Connect Email
            </Button>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/help-desk"
          nextPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}

import { cn } from "@dashboard/utils/ui";
import {
  KeyboardEventHandler,
  useState,
  useMemo,
  useEffect,
  ClipboardEvent,
} from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useSendMessage } from "../../-hooks/useSendMessage";
import { useConversation } from "../../-hooks/useConversation.hook";
import { MessageIcon } from "./MessageIcon";
import { Route } from "../..";
import { Languages, Undo, Wand2, Loader2 } from "lucide-react";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { Button } from "@dashboard/common/ui/button";
import { useTranslateText } from "../../-hooks/useTranslateText";
import { ChannelType } from "@db";
import { Separator } from "@dashboard/common/ui/separator";
import { Input } from "@dashboard/common/ui/input";
import { useCreateExternalThread } from "../../-hooks/useCreateExternalThread";
import { useSendExternalMessage } from "../../-hooks/useSendExternalMessage";
import { z } from "zod";
import { toast } from "sonner";
import { MessageButton } from "./MessageButton";
import { MacroAction } from "@be/modules/macros/macros.types";
import { DashboardAction } from "../../../-components/Macros/DashboardAction";
import { MacroCommand } from "../../../-components/Macros/MacroCommand";
import { AttachmentButton } from "../../../-components/FileUpload/AttachmentButton";
import { AttachedFile } from "../../../-components/FileUpload/AttachedFile";
import { useFileUpload } from "../../../-hooks/useFileUpload";
import { MacroRecommendations } from "../MacroRecommendations";
import { useRefineMessage } from "../../-hooks/useRefineMessage";
import { useMacroData } from "../../-hooks/useMacroData";
import { useRefineExternalMessage } from "../../-hooks/useRefineExternalMessage";

interface MessageInputProps {
  tab: "customer" | "external";
  replyChannel: ChannelType;
}

// TODO: Input should be refactored to use a form to allow for input validation
// and fix the bug where inputs are cleared even when the message fails to send

export function MessageInput({ tab, replyChannel }: MessageInputProps) {
  const { publicId } = Route.useParams();

  const [inputText, setInputText] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [externalInputText, setExternalInputText] = useState("");
  const [originalExternalText, setOriginalExternalText] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [actions, setActions] = useState<MacroAction[]>([]);
  const [isRefining, setIsRefining] = useState(false);
  const [attachments, setAttachments] = useState<
    {
      fileId: string;
      filename: string;
    }[]
  >([]);
  const [externalAttachments, setExternalAttachments] = useState<
    {
      fileId: string;
      filename: string;
    }[]
  >([]);

  const { translate } = useTranslateText({
    onData: (data) => {
      if (tab === "customer") {
        setOriginalText(inputText);
        setInputText(data);
      } else {
        setOriginalExternalText(externalInputText);
        setExternalInputText(data);
      }
    },
  });

  const conversation = useConversation();
  const macroData = useMacroData();
  const sendMessage = useSendMessage();
  const createExternalThread = useCreateExternalThread();
  const sendExternalMessage = useSendExternalMessage();

  const targetLang = useMemo(() => {
    if (conversation.messages.length > 0) {
      return conversation.messages.filter((m) => m.sender === "customer").at(-1)
        ?.language;
    }
  }, [conversation.messages]);

  useEffect(() => {
    setOriginalText("");
    setInputText("");
    setOriginalExternalText("");
    setExternalInputText("");
    setEmail("");
    setSubject("");
    setActions([]);
    setAttachments([]);
    setExternalAttachments([]);
  }, [publicId]);

  const [inputIsFocused, setInputIsFocused] = useState(false);

  const customerEmail = conversation.customer?.email;

  const onClickSendMessage = ({ action }: { action?: MacroAction }) => {
    if (isRefining) return;
    switch (tab) {
      case "customer": {
        if (!inputText) return;

        let allActions = [...actions];
        if (action) {
          allActions.push(action);
          allActions = allActions.filter(
            (v, i, a) => a.findIndex((t) => t.type === v.type) === i,
          );
        }

        sendMessage({
          text: inputText,
          channel: replyChannel,
          actions: allActions,
          fileIds: attachments.map((f) => f.fileId),
        });

        setAttachments([]);

        setInputText("");
        setOriginalText("");
        setActions([]);

        break;
      }
      case "external": {
        if (!externalInputText) return;

        if (conversation.externalThread) {
          sendExternalMessage({
            text: externalInputText,
            externalEmailThreadId: conversation.externalThread.emailThreadId,
            fileIds: externalAttachments.map((f) => f.fileId),
          });
        } else {
          if (!email || !subject) return;

          const result = z.string().email().safeParse(email);
          if (!result.success) {
            toast.error("Invalid email address", {
              position: "bottom-center",
            });
            return;
          }

          createExternalThread({
            text: externalInputText,
            email,
            subject,
            fileIds: externalAttachments.map((f) => f.fileId),
          });
        }

        setEmail("");
        setSubject("");
        setExternalInputText("");
        setOriginalExternalText("");
        setExternalAttachments([]);

        break;
      }
    }
  };

  const onClickTranslateInput = () => {
    if (!targetLang) return;

    if (tab === "customer") {
      if (!inputText) return;
      translate({ text: inputText, targetLang });
    } else {
      if (!externalInputText) return;
      translate({ text: externalInputText, targetLang });
    }
  };

  const handleKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      onClickSendMessage({});
      event.preventDefault();
    }
  };

  const onAttachmentUpload = (file: { fileId: string; filename: string }) => {
    if (tab === "customer") {
      setAttachments((prev) => [...prev, file]);
    } else {
      setExternalAttachments((prev) => [...prev, file]);
    }
  };

  const onAttachmentRemove = ({ fileId }: { fileId: string }) => {
    if (tab === "customer") {
      setAttachments((prev) => prev.filter((f) => f.fileId !== fileId));
    } else {
      setExternalAttachments((prev) => prev.filter((f) => f.fileId !== fileId));
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isRefining) return;

    switch (tab) {
      case "customer":
        setInputText(e.target.value);
        break;
      case "external":
        setExternalInputText(e.target.value);
        break;
    }
  };

  const { uploadFiles } = useFileUpload();

  const handlePaste = async (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (isRefining) {
      e.preventDefault();
      return;
    }
    const items = e.clipboardData.items;

    const files = Array.from(items)
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile()) as File[];

    if (files.length === 0) {
      return;
    }

    e.preventDefault();

    const uploadedFiles = await uploadFiles({
      files,
      businessId: conversation.businessId,
    });

    switch (tab) {
      case "customer": {
        setAttachments((prev) =>
          prev.concat(
            uploadedFiles.map((file) => ({
              fileId: file.fileId,
              filename: file.filename,
            })),
          ),
        );
        break;
      }
      case "external": {
        setExternalAttachments((prev) =>
          prev.concat(
            uploadedFiles.map((file) => ({
              fileId: file.fileId,
              filename: file.filename,
            })),
          ),
        );
        break;
      }
    }
  };

  const refineMessage = useRefineMessage();
  const refineExternalMessage = useRefineExternalMessage();

  const onClickRefineMessage = () => {
    if (isRefining) return;
    if (tab === "customer") {
      if (!inputText) return;

      setIsRefining(true);

      refineMessage({
        currentMessage: inputText,
        replyChannel,
        onSuccess: (refinedMessage) => {
          setInputText(refinedMessage);
          setIsRefining(false);
        },
        onError: () => {
          setIsRefining(false);
        },
      });
    } else {
      if (!externalInputText) return;

      setIsRefining(true);

      refineExternalMessage({
        currentMessage: externalInputText,
        onSuccess: (refinedMessage) => {
          setExternalInputText(refinedMessage);
          setIsRefining(false);
        },
        onError: () => {
          setIsRefining(false);
        },
      });
    }
  };

  return (
    <div className="mb-3 w-full px-7">
      <div
        className={cn(
          "flex flex-col overflow-x-hidden break-words rounded-2xl border border-gray-100 px-4 pb-2 pt-3",
          inputIsFocused ? "shadow-xl" : "shadow-md",
        )}
      >
        <div className="flex flex-row items-center">
          <MessageIcon />

          {tab === "customer" ? (
            <span className="ml-1 text-sm font-medium">{`Reply via ${replyChannel}`}</span>
          ) : (
            <span className="ml-1 text-sm font-medium">Email</span>
          )}

          {tab === "customer" && replyChannel === "email" && customerEmail && (
            <span
              className={cn(
                "ml-2 text-sm text-gray-600",
                inputIsFocused ? "" : "hidden",
              )}
            >
              {customerEmail}
            </span>
          )}
        </div>

        {tab === "external" && (
          <div>
            <div className="flex items-center">
              <div className="w-16 text-sm text-gray-500">From</div>
              <span className="flex h-9 items-center px-1 py-1 text-sm text-black">
                {conversation.emailIntegration?.address}
              </span>
            </div>
            <Separator />
            <div className="flex items-center">
              <div className="w-16 text-sm text-gray-500">To</div>
              {conversation.externalThread ? (
                <span className="flex h-9 items-center px-1 py-1 text-sm text-black">
                  {conversation.externalThread.recipientEmail}
                </span>
              ) : (
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setInputIsFocused(true)}
                  onBlur={() => setInputIsFocused(false)}
                  className="border-0 text-sm text-black shadow-none focus-visible:ring-0"
                />
              )}
            </div>
            <Separator />
            <div className="flex items-center">
              <div className="w-16 text-sm text-gray-500">Subject</div>
              {conversation.externalThread ? (
                <span className="flex h-9 items-center px-1 py-1 text-sm text-black">
                  {conversation.externalThread.subject}
                </span>
              ) : (
                <Input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  onFocus={() => setInputIsFocused(true)}
                  onBlur={() => setInputIsFocused(false)}
                  className="border-0 text-sm text-black shadow-none focus-visible:ring-0"
                />
              )}
            </div>
            <Separator />
          </div>
        )}

        <TextareaAutosize
          minRows={tab === "external" ? 4 : 2}
          maxRows={10}
          className="block w-full resize-none border-0 px-0 py-3 text-sm text-black outline-none focus:ring-0 disabled:bg-transparent"
          placeholder="Type here..."
          value={tab === "customer" ? inputText : externalInputText}
          onChange={onInputChange}
          onPaste={(event) => void handlePaste(event)}
          onKeyDown={handleKeyDown}
          onFocus={() => setInputIsFocused(true)}
          onBlur={() => setInputIsFocused(false)}
          disabled={isRefining}
        />

        <div className="pb-2">
          {conversation.enableMacroRecommendations && (
            <MacroRecommendations
              setInput={setInputText}
              setActions={setActions}
            />
          )}

          {(actions.length > 0 ||
            (tab === "customer" && attachments.length > 0) ||
            (tab === "external" && externalAttachments.length > 0)) && (
            <div className="flex flex-row flex-wrap items-center justify-start gap-2 pt-2 text-sm">
              {actions.map((action, index) => (
                <DashboardAction
                  key={index}
                  action={action}
                  remove={() => {
                    setActions((prev) => prev.filter((_, i) => i !== index));
                  }}
                />
              ))}
              {(tab === "customer" ? attachments : externalAttachments).map(
                (attachment, index) => (
                  <AttachedFile
                    key={index}
                    filename={attachment.filename}
                    fileId={attachment.fileId}
                    remove={onAttachmentRemove}
                  />
                ),
              )}
            </div>
          )}
        </div>
        <div className="flex h-8 flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            <MacroCommand
              setInput={
                tab === "customer" ? setInputText : setExternalInputText
              }
              setActions={setActions}
              macroData={macroData}
              disabled={isRefining}
            />
            <AttachmentButton
              onUpload={onAttachmentUpload}
              businessId={conversation.businessId}
              disabled={isRefining}
            />
            {targetLang &&
              conversation.enableTranslation &&
              ((tab === "customer" ? originalText : originalExternalText) ===
              "" ? (
                <Tooltip
                  triggerAsChild
                  trigger={
                    <Button
                      variant="select"
                      onClick={onClickTranslateInput}
                      disabled={isRefining}
                      className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
                    >
                      <Languages className="h-5 w-5" />
                    </Button>
                  }
                  content="Translate message"
                />
              ) : (
                <Tooltip
                  triggerAsChild
                  trigger={
                    <Button
                      variant="select"
                      onClick={() => {
                        if (tab === "customer") {
                          setInputText(originalText);
                          setOriginalText("");
                        } else {
                          setExternalInputText(originalExternalText);
                          setOriginalExternalText("");
                        }
                      }}
                      disabled={isRefining}
                      className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
                    >
                      <Undo className="m-2 h-5 w-5" />
                    </Button>
                  }
                  content="Undo last translation"
                />
              ))}
            {((tab === "customer" && inputText !== "") ||
              (tab === "external" && externalInputText !== "")) && (
              <Tooltip
                triggerAsChild
                trigger={
                  <Button
                    variant="select"
                    onClick={onClickRefineMessage}
                    disabled={isRefining}
                    className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
                  >
                    {isRefining ? (
                      <Loader2 className="h-5 w-5 animate-spin" />
                    ) : (
                      <Wand2 className="h-5 w-5" />
                    )}
                  </Button>
                }
                content="Refine message"
              />
            )}
          </div>
          <div>
            <MessageButton
              onClickSendMessage={onClickSendMessage}
              inputText={tab === "customer" ? inputText : externalInputText}
              isRefining={isRefining}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import { Button } from "@dashboard/common/ui/button";
import { useNavigate } from "@tanstack/react-router";

interface SubscriptionProps {
  organizationSlug: string;
  businessSlug: string;
}

export function Subscription({
  businessSlug,
  organizationSlug,
}: SubscriptionProps) {
  const navigate = useNavigate();

  return (
    <>
      <p className="mb-4 text-justify">
        Choose the subscription plan that best fits your needs and unlock
        Octocom for your business.
      </p>
      <Button
        className="w-full"
        onClick={() =>
          void navigate({
            to: "/organization/$organizationSlug/settings/$businessSlug/billing",
            params: {
              organizationSlug,
              businessSlug,
            },
          })
        }
      >
        View Plans
      </Button>
    </>
  );
}

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ArticleForm, articleFormSchema } from "../-utils/articleForm";
import { useCreateArticle } from "../-hooks/useCreateArticle";
import { ArticleFormBody } from "./ArticleFormBody";

export function CreateArticleForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<ArticleForm>({
    resolver: zodResolver(articleFormSchema),
    defaultValues: {
      title: "",
      content: "",
      isPublic: true,
    },
  });

  const { onSubmit: onSubmitMutation } = useCreateArticle({ setOpen });

  const onSubmit = (data: ArticleForm) => {
    onSubmitMutation(data);

    form.reset();
  };

  return <ArticleFormBody form={form} onSubmit={onSubmit} />;
}

import { useNavigate } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { useCompleteOnboardingStep } from "../-hooks/useCompleteOnboardingStep";
import { Check } from "lucide-react";

export function AddTeamMembers({
  businessSlug,
  organizationSlug,
  completed,
}: {
  businessSlug: string;
  organizationSlug: string;
  completed: boolean;
}) {
  const navigate = useNavigate();
  const completeStep = useCompleteOnboardingStep();

  return (
    <div className="space-y-4">
      <p className="text-justify">
        Invite your team members to collaborate on Octocom. Add agents and
        managers to handle customer conversations, manage content, and configure
        settings.
      </p>
      <Button
        onClick={() =>
          void navigate({
            to: "/organization/$organizationSlug/settings/$businessSlug/team",
            params: {
              organizationSlug,
              businessSlug,
            },
          })
        }
        className="w-full"
      >
        Manage Team Members
      </Button>

      {!completed ? (
        <Button
          onClick={() => completeStep({ step: "team" })}
          variant="secondary"
          className="w-full"
        >
          Complete
        </Button>
      ) : (
        <Button className="w-full">
          <Check className="mr-2 h-4 w-4" />
          Completed
        </Button>
      )}
    </div>
  );
}

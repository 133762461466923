import { z } from "zod";

export const flowFormSchema = z.object({
  id: z.string().optional(),
  title: z
    .string({
      required_error: "Title is required",
      invalid_type_error: "Title is required",
    })
    .min(1, "Title is required"),
  whenToFollow: z
    .string({
      required_error: "When to follow is required",
      invalid_type_error: "When to follow is required",
    })
    .min(1, "When to follow is required"),
  variants: z.array(
    z.object({
      id: z.string().optional(),
      title: z
        .string({
          required_error: "Title is required",
          invalid_type_error: "Title is required",
        })
        .min(1, "Title is required"),
      associatedThreatIds: z.array(z.string()),
      ignoredThreatIds: z.array(z.string()),
      requiredConditions: z.array(z.string()),
      whenToFollow: z
        .string({
          required_error: "When to follow is required",
          invalid_type_error: "When to follow is required",
        })
        .nullish()
        .transform((v) => v ?? ""),
      botInstructions: z
        .string({
          required_error: "Bot instructions are required",
          invalid_type_error: "Bot instructions are required",
        })
        .min(1, "Bot instructions are required"),
    }),
  ),
  isLive: z.boolean(),
  orderIdRequired: z.boolean(),
});

export type FlowForm = z.infer<typeof flowFormSchema>;

import { Button } from "@dashboard/common/ui/button";
import { useCompleteOnboardingStep } from "../-hooks/useCompleteOnboardingStep";
import { Check } from "lucide-react";

export function TestYourBot({ completed }: { completed: boolean }) {
  const completeStep = useCompleteOnboardingStep();

  return (
    <>
      <div className="space-y-6">
        <div className="prose prose-sm max-w-none">
          <p className="text-black">
            Test your bot&apos;s capabilities by engaging in a natural
            conversation. See how it handles questions about your business
            operations, products and services.
          </p>
        </div>

        <div className="hover:bg-blue-light bg-muted hover:border-blue flex flex-col items-start justify-start gap-2 rounded-lg border p-4 transition-all duration-200 ease-in-out hover:shadow-lg">
          <div className="flex w-full items-start justify-between">
            <p className="text-lg font-medium text-gray-800">
              Try asking questions like:
            </p>
          </div>
          <ul className="grid gap-2 text-sm text-gray-600">
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              &quot;What are your business hours?&quot;
            </li>
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              &quot;Do you ship internationally?&quot;
            </li>
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              &quot;What is your customer support email?&quot;
            </li>
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              &quot;What kind of products do you sell?&quot;
            </li>
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              &quot;What is your return policy?&quot;
            </li>
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              &quot;What is the status of my order?&quot;
            </li>
            <li className="text-primary flex items-center gap-2 rounded-md p-1">
              <span className="select-none">→</span>
              And any others that are relevant to your particular business...
            </li>
          </ul>
        </div>
        <p className="text-sm text-black">
          Don&apos;t worry if you notice any knowledge gaps - you can address
          those in the next step.
        </p>
        <div className="flex flex-col gap-4">
          {!completed ? (
            <Button
              onClick={() => completeStep({ step: "test" })}
              className="w-full"
              variant="secondary"
            >
              Complete
            </Button>
          ) : (
            <Button className="w-full">
              <Check className="mr-2 h-4 w-4" />
              Completed
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

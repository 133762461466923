import { format } from "date-fns";
import { useConversation } from "../../-hooks/useConversation.hook";
import { ConversationTopBar } from "../../../../-components/ConversationTopBar";
import { ConversationDetailSection } from "./ConversationDetailSection";
import { AssigneeSelect } from "./AssigneeSelect";
import { TagSection } from "./TagSection";
import { Route } from "../..";
import { useMemo, useState } from "react";
import { DetailsAccordion } from "./DetailsAccordion";
import { RecentConversations } from "./RecentConversations";
import { Button } from "@dashboard/common/ui/button";
import { PanelRightClose, PanelRightOpen } from "lucide-react";
import { cn } from "@dashboard/utils/ui";
import { env } from "@dashboard/env";
import { Orders } from "./Orders/Orders";

export function ConversationDetails() {
  const { hasExternalTicketConfig, businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const conversation = useConversation();
  const [open, setOpen] = useState(true);

  const business = useMemo(
    () => businesses.find((b) => b.id === conversation.businessId),
    [businesses, conversation.businessId],
  );

  if (!business) {
    throw new Error("Business not found");
  }

  const { customer } = conversation;

  const languages = [
    ...new Set(
      conversation.messages
        .map((m) => m.language?.toUpperCase())
        .filter((l): l is string => !!l),
    ),
  ];

  return (
    <>
      <div className="fixed right-0 top-0 z-50 flex h-16 w-10 items-center justify-center">
        <Button
          variant="ghost"
          className="hover:text-blue hover:bg-blue-light h-auto w-auto p-1 text-black "
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <PanelRightClose className="h-5 w-5" />
          ) : (
            <PanelRightOpen className="h-5 w-5" />
          )}
        </Button>
      </div>

      <div
        className={cn(
          "border-b-gray-lightest flex h-16 w-0 items-center justify-center border-b transition-all duration-200 ease-in-out",
          !open && "w-4",
        )}
      />
      <div
        className={cn(
          "bg-background border-l-gray-light flex h-full w-[24%] min-w-[250px] max-w-[400px] flex-col overflow-x-auto border-l pb-5 transition-all duration-200 ease-in-out",
          !open && "w-0 min-w-0",
        )}
      >
        <ConversationTopBar>
          <h2 className="text-xl font-medium text-black">Details</h2>
        </ConversationTopBar>

        <div className="divide-gray-lightest space-y-5 divide-y overflow-y-auto">
          {!hasExternalTicketConfig && (
            <>
              {conversation.isHandedOff && (
                <ConversationDetailSection
                  details={[
                    {
                      label: "Assignee:",
                      value: <AssigneeSelect />,
                    },
                  ]}
                />
              )}
            </>
          )}

          {customer && <TagSection />}

          {customer && (
            <div>
              <div className="pl-7 pt-4 text-xs font-medium uppercase">
                Customer Details
              </div>
              <ConversationDetailSection
                details={[
                  ...(customer.name
                    ? [
                        {
                          label: "Name:",
                          value: customer.name,
                          copy: true,
                        },
                      ]
                    : []),
                  {
                    label: "Email:",
                    value: customer.email,
                    copy: true,
                  },
                  ...(customer.phone
                    ? [
                        {
                          label: "Phone:",
                          value: customer.phone,
                          copy: true,
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          )}

          {customer && <Orders />}

          <RecentConversations />

          <div>
            <div className="pl-7 pt-4 text-xs font-medium uppercase">
              Conversation Details
            </div>
            <ConversationDetailSection
              details={[
                {
                  label: "Business:",
                  value: business.name,
                },
                {
                  label: "URL:",
                  value: `${env.DASHBOARD_BASE_URL}/organization/${organizationSlug}/conversation/${conversation.publicId}`,
                  copy: true,
                },
                {
                  label: "ID:",
                  value: conversation.publicId,
                  copy: true,
                },
                {
                  label: "Start date:",
                  value: format(new Date(conversation.createdAt), "PPp"),
                  copy: true,
                },
                ...(languages.length > 0
                  ? [
                      {
                        label:
                          languages.length === 1 ? "Language:" : "Languages:",
                        value: languages.join(", "),
                      },
                    ]
                  : []),
                {
                  label: "Message count:",
                  value: conversation.messages.length,
                },
                {
                  label: "Channel:",
                  value: conversation.latestChannel
                    .split("-")
                    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                    .join(" "),
                },
                {
                  label: "Is closed:",
                  value: conversation.closedAt ? "Yes" : "No",
                },
                ...(conversation.closedAt
                  ? [
                      {
                        label: "Closed at:",
                        value: format(new Date(conversation.closedAt), "PPp"),
                        copy: true,
                      },
                    ]
                  : []),
                {
                  label: "Is handed off:",
                  value: conversation.handedOffAt ? "Yes" : "No",
                },
                ...(conversation.handedOffAt
                  ? [
                      {
                        label: "Handed off at:",
                        value: format(
                          new Date(conversation.handedOffAt),
                          "PPp",
                        ),
                        copy: true,
                      },
                    ]
                  : []),
                {
                  label: "Order ID:",
                  value: conversation.orderId ?? "Unknown",
                  copy: true,
                },
              ]}
            />
          </div>

          <DetailsAccordion />
        </div>
      </div>
    </>
  );
}

import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { Textarea } from "@dashboard/common/ui/textarea";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useCreateArticle } from "../-hooks/useCreateArticle";
import { useState } from "react";
import { Check, Loader2, X } from "lucide-react";

const formSchema = z.object({
  title: z
    .string({
      required_error: "Title is required",
      invalid_type_error: "Title is required",
    })
    .min(1, "Title is required"),
  content: z
    .string({
      required_error: "Content is required",
      invalid_type_error: "Content is required",
    })
    .min(1, "Content is required"),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/knowledge/article",
)({
  component: ArticlePage,
});

function ArticlePage() {
  const [syncStatus, setSyncStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const { createArticle } = useCreateArticle({
    onSuccess: () => {
      setSyncStatus("success");
      setTimeout(() => {
        setSyncStatus("idle");
      }, 2000);

      form.reset();
    },
    onError: () => {
      setSyncStatus("error");
    },
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      content: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    createArticle(values);
  };

  const SyncStatus = () => {
    switch (syncStatus) {
      case "idle":
        return <span>Submit Article</span>;
      case "loading":
        return (
          <>
            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
            <span>Submitting Article...</span>
          </>
        );
      case "success":
        return (
          <>
            <Check className="mr-2 h-5 w-5" />
            <span>Article Submitted</span>
          </>
        );
      case "error":
        return (
          <>
            <X className="mr-2 h-5 w-5" />
            <span>Failed to Submit Article</span>
          </>
        );
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Add an Article
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Enter the title and content of your article below.
      </h2>
      <div className="w-full max-w-3xl py-8">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter article title"
                      className="h-10"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Content</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Enter article content"
                      className="h-48"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="h-10 bg-[#041627] text-white"
              disabled={syncStatus === "loading"}
            >
              <SyncStatus />
            </Button>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={false}
        />
      </div>
    </div>
  );
}

import { useCallback, useEffect, useRef, useState } from "react";
import { Route } from "..";
import { useConversation } from "../-hooks/useConversation.hook";
import { Message } from "./Message";
import { Event } from "./Event";
import { MessageInput } from "./Input/MessageInput";
import { ContentTopBar } from "./ContentTopBar";
import { Tabs, TabsTrigger, TabsList } from "@dashboard/common/ui/tabs";
import { ExternalMessage } from "./ExternalMessage";
import { determineReplyChannel } from "../-utils/showInput";
import { useSubscription } from "../-hooks/useSubscription";
import { FeedbackFormInput } from "./FeedbackFormInput";
import { useFeedback } from "../-hooks/useFeedback";
import { format } from "date-fns";

type Message = ReturnType<typeof useConversation>["messages"][number];
type NonExternalMessage = Exclude<Message, { sender: "external" }>;
type ExternalMessage = Extract<
  Message,
  { sender: "external" } | { sender: "agent" }
>;

export function ConversationContent() {
  const { user } = Route.useRouteContext();
  const [activeTab, setActiveTab] = useState<"customer" | "external">(
    "customer",
  );

  const conversation = useConversation();

  const feedback = useFeedback();
  const showFeedbackForm =
    !user.isAdmin &&
    conversation.enableFeedback &&
    !feedback &&
    activeTab === "customer";

  const [showFeedbackInput, setShowFeedbackInput] = useState(showFeedbackForm);

  useEffect(() => {
    setShowFeedbackInput(showFeedbackForm);
  }, [showFeedbackForm, conversation]);

  const { publicId } = Route.useParams();
  const { hasExternalTicketConfig } = Route.useRouteContext();

  const { showInput, replyChannel } = determineReplyChannel({
    activeTab,
    hasExternalTicketConfig,
    customerEmailAvailable: !!conversation.customer,
    emailIntegrationAvailable: !!conversation.emailIntegration,
    ...conversation,
  });

  const showTabs = !!conversation.externalThread || activeTab === "external";

  const conversationContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    requestAnimationFrame(() => {
      if (conversationContainerRef.current) {
        conversationContainerRef.current.scrollTo({
          top: conversationContainerRef.current.scrollHeight,
          behavior: "auto",
        });
      }
    });
  }, [conversationContainerRef]);

  useSubscription();

  useEffect(() => {
    scrollToBottom();
  }, [conversation, scrollToBottom]);

  useEffect(() => {
    setActiveTab("customer");
  }, [publicId]);

  return (
    <div className="bg-background flex h-full flex-grow flex-col">
      <ContentTopBar setTab={setActiveTab} />

      {showTabs && (
        <Tabs
          value={activeTab}
          onValueChange={(value) =>
            setActiveTab(value as "customer" | "external")
          }
          defaultValue="customer"
          className="border-b p-2"
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="customer">Customer Thread</TabsTrigger>
            <TabsTrigger value="external">External Thread</TabsTrigger>
          </TabsList>
        </Tabs>
      )}

      <div
        className="flex-grow overflow-auto px-2 pb-5 pt-3"
        ref={conversationContainerRef}
      >
        {activeTab === "customer"
          ? conversation.parts
              .filter(
                (part) =>
                  part.type === "event" ||
                  !(
                    "externalEmailThreadId" in part &&
                    part.externalEmailThreadId
                  ),
              )
              .map((part) =>
                part.type === "message" ? (
                  <Message key={part.id} message={part as NonExternalMessage} />
                ) : (
                  <Event
                    key={part.id}
                    event={part.event}
                    createdAt={part.createdAt}
                    metadata={part.metadata}
                  />
                ),
              )
          : conversation.parts
              .filter(
                (part) =>
                  part.type === "message" &&
                  "externalEmailThreadId" in part &&
                  part.externalEmailThreadId,
              )
              .map((part) => (
                <ExternalMessage
                  key={part.id}
                  message={part as ExternalMessage}
                />
              ))}
        {conversation.snoozedUntil && (
          <span className="flex flex-row-reverse pr-10 pt-3 text-sm font-normal text-gray-500">
            Conversation Snoozed until{" "}
            {format(conversation.snoozedUntil, "MMM d, yyyy HH:mm")}
          </span>
        )}
      </div>

      {showFeedbackInput ? (
        <FeedbackFormInput showMessageInput={showInput} />
      ) : (
        showInput && (
          <MessageInput tab={activeTab} replyChannel={replyChannel} />
        )
      )}
    </div>
  );
}

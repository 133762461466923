import { createFileRoute } from "@tanstack/react-router";
import { Crown, Rocket, Zap } from "lucide-react";
import { useState } from "react";
import { CurrentPlanCard } from "./-components/CurrentPlanCard";
import { PlanSelection } from "./-components/PlanSelection";
import { Plan, ShopifySubscription } from "./-components/types";
import { useSubscribe } from "./-hooks/useSubscribe";

const plans: Plan[] = [
  {
    id: "Basic",
    name: "Basic",
    price: 100,
    maxConversations: 350,
    overageFee: 40,
    icon: Rocket,
  },
  {
    id: "Advanced",
    name: "Advanced",
    price: 300,
    maxConversations: 1200,
    overageFee: 36,
    icon: Zap,
  },
  {
    id: "Enterprise",
    name: "Enterprise",
    price: 800,
    maxConversations: 3200,
    overageFee: 30,
    icon: Crown,
  },
];

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/",
)({
  component: ShopifySubscriptionManagement,
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.shopify.getSubscription.ensureData({
      businessSlug,
    });
  },
});

function ShopifySubscriptionManagement() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const subscribe = useSubscribe();

  const [subscription] =
    trpc.dashboard.shopify.getSubscription.useSuspenseQuery({ businessSlug });

  const subscriptionType = (subscription?.subscriptionType ??
    "Basic") as ShopifySubscription;

  const [selectedPlan, setSelectedPlan] =
    useState<ShopifySubscription>(subscriptionType);

  const currentPlan = subscription
    ? plans.find((plan) => plan.id === subscription.subscriptionType)
    : undefined;

  const handleConfirm = () => {
    subscribe({ subscriptionType: selectedPlan });
  };

  return (
    <div className="relative py-0">
      <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">Billing</h1>
      </div>

      <div className="px-10 py-5">
        <div className="space-y-8">
          <CurrentPlanCard currentPlan={currentPlan} />
          <PlanSelection
            plans={plans}
            selectedPlan={selectedPlan}
            currentPlan={currentPlan?.id}
            onPlanChange={setSelectedPlan}
            onConfirm={handleConfirm}
          />
        </div>
      </div>
    </div>
  );
}

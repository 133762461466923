import { Address } from "@be/modules/orders/orders.types";
import { OrderDetailSection } from "./OrderDetailSection";

interface AddressDetailsSectionProps {
  address: Address | undefined;
  name: string | undefined;
}

export function AddressDetailsSection({
  address,
  name,
}: AddressDetailsSectionProps) {
  return (
    <OrderDetailSection
      details={[
        {
          label: "Name:",
          value: name && name !== "" ? name : "N/A",
        },
        {
          label: "Address 1:",
          value: address?.line1 && address.line1 !== "" ? address.line1 : "N/A",
        },
        {
          label: "Address 2:",
          value: address?.line2 && address.line2 !== "" ? address.line2 : "N/A",
        },
        {
          label: "City:",
          value: address?.city && address.city !== "" ? address.city : "N/A",
        },
        {
          label: "Province:",
          value:
            address?.province && address.province !== ""
              ? address.province
              : "N/A",
        },
        {
          label: "Zip:",
          value: address?.zip && address.zip !== "" ? address.zip : "N/A",
        },
        {
          label: "Country:",
          value:
            address?.country && address.country !== ""
              ? address.country
              : "N/A",
        },
      ]}
    />
  );
}

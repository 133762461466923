import { cn } from "@dashboard/utils/ui";
import { ConversationTopBar } from "../../../-components/ConversationTopBar";
import { useConversation } from "../-hooks/useConversation.hook";
import { Route } from "..";
import { Tooltip, TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { Toggle } from "@dashboard/common/ui/toggle";
import {
  Languages,
  Bug,
  Forward,
  Trash2,
  Send,
  PanelBottomClose,
  PanelBottomOpen,
  AlarmClockOff,
  AlarmClock,
  EyeOff,
  Eye,
} from "lucide-react";
import { useTranslation } from "../-hooks/useTranslation";
import { Button } from "@dashboard/common/ui/button";
import { DebugModal } from "./DebugModal";
import { useTakeOver } from "../-hooks/useTakeOver";
import { DeletionModal } from "./DeletionModal";
import { useClose } from "../-hooks/useClose";
import { useReopen } from "../-hooks/useReopen";
import { useUnsnooze } from "../-hooks/useUnsnooze";
import { DatetimeForm } from "@dashboard/common/Calendar/DatetimeForm";
import { useState } from "react";
import { useSnooze } from "../-hooks/useSnooze";
import { Dialog, DialogContent } from "@dashboard/common/ui/dialog";
import { HideModal } from "./HideModal";

interface ContentTopBarProps {
  setTab: (tab: "customer" | "external") => void;
}

export function ContentTopBar({ setTab }: ContentTopBarProps) {
  const [snoozeDialogOpen, setSnoozeDialogOpen] = useState(false);

  const conversation = useConversation();

  const { user, hasExternalTicketConfig } = Route.useRouteContext();

  const hasNonUserLanguageMessage = conversation.messages
    .map((m) => m.language)
    .filter((l): l is string => !!l)
    .some((l) => l !== user.translationLanguage);

  const { translate, translationIsEnabled } = useTranslation();

  const takeOver = useTakeOver();
  const close = useClose();
  const reopen = useReopen();
  const unsnooze = useUnsnooze();
  const snooze = useSnooze();

  const handleSnooze = (until: Date) => {
    snooze(until);
    setSnoozeDialogOpen(false);
  };

  const customer = conversation.customer;

  const showTakeOver = !!customer || conversation.enableLiveChat;

  const showCreateExternalThread =
    !hasExternalTicketConfig && conversation.emailIntegration;

  return (
    <ConversationTopBar>
      <Dialog open={snoozeDialogOpen} onOpenChange={setSnoozeDialogOpen}>
        <DialogContent className="w-auto overflow-hidden p-0">
          <DatetimeForm onSelect={handleSnooze} actionLabel="Snooze" />
        </DialogContent>
      </Dialog>

      <div
        className={cn(
          "flex w-full flex-row",
          customer?.email ? "justify-between" : "justify-end",
        )}
      >
        {customer?.email && (
          <div className="flex max-w-[16vw] items-center">
            <TruncatedTooltip
              text={customer.email}
              className="text-xl font-medium text-black"
            />
          </div>
        )}
        <div className="flex flex-row gap-2">
          {showCreateExternalThread && (
            <Tooltip
              disableHoverableContent
              triggerAsChild
              side="bottom"
              trigger={
                <Button
                  type="button"
                  variant="outline"
                  size="toggle"
                  onClick={() => setTab("external")}
                >
                  <Send height={18} width={18} />
                </Button>
              }
              content={
                <p className="font-medium text-black">Create external thread</p>
              }
            />
          )}

          {conversation.enableTranslation && hasNonUserLanguageMessage && (
            <Tooltip
              disableHoverableContent
              triggerAsChild
              side="bottom"
              trigger={
                <Toggle
                  pressed={translationIsEnabled}
                  onPressedChange={(val) => {
                    translate(val);
                  }}
                  aria-label="Toggle translation"
                  variant="outline"
                >
                  <Languages
                    height={18}
                    width={18}
                    color={translationIsEnabled ? "#3b82f6" : "black"}
                    className={!translationIsEnabled ? "opacity-80" : undefined}
                  />
                </Toggle>
              }
              content={
                <p className="font-medium text-black">Translate conversation</p>
              }
            />
          )}
          {user.isAdmin && (
            <Tooltip
              disableHoverableContent
              triggerAsChild
              side="bottom"
              trigger={
                <DebugModal>
                  <Button variant="outline" size="toggle">
                    <Bug height={18} width={18} />
                  </Button>
                </DebugModal>
              }
              content={
                <p className="font-medium text-black">Debug conversation</p>
              }
            />
          )}
          {user.isAdmin && (
            <Tooltip
              disableHoverableContent
              triggerAsChild
              side="bottom"
              trigger={
                <DeletionModal>
                  <Button variant="outline" size="toggle">
                    <Trash2 height={18} width={18} />
                  </Button>
                </DeletionModal>
              }
              content={
                <p className="font-medium text-black">Delete conversation</p>
              }
            />
          )}
          {user.isAdmin && (
            <Tooltip
              disableHoverableContent
              triggerAsChild
              side="bottom"
              trigger={
                <HideModal>
                  <Button variant="outline" size="toggle">
                    {conversation.isHidden ? (
                      <Eye height={18} width={18} />
                    ) : (
                      <EyeOff height={18} width={18} />
                    )}
                  </Button>
                </HideModal>
              }
              content={
                <p className="font-medium text-black">Hide conversation</p>
              }
            />
          )}
          {conversation.isHandedOff &&
            !hasExternalTicketConfig &&
            (conversation.isClosed ? (
              <Tooltip
                disableHoverableContent
                triggerAsChild
                side="bottom"
                trigger={
                  <Button variant="default" size="toggle" onClick={reopen}>
                    <div className="flex items-center gap-2">
                      <PanelBottomOpen
                        width={16}
                        height={16}
                        strokeWidth={2.5}
                      />
                    </div>
                  </Button>
                }
                content={
                  <p className="font-medium text-black">Reopen conversation</p>
                }
              />
            ) : (
              <>
                <Tooltip
                  disableHoverableContent
                  triggerAsChild
                  side="bottom"
                  trigger={
                    <Button variant="default" size="toggle" onClick={close}>
                      <div className="flex items-center gap-2">
                        <PanelBottomClose
                          width={16}
                          height={16}
                          strokeWidth={2.5}
                        />
                      </div>
                    </Button>
                  }
                  content={
                    <p className="font-medium text-black">Close conversation</p>
                  }
                />
                {conversation.isSnoozed ? (
                  <Tooltip
                    disableHoverableContent
                    triggerAsChild
                    side="bottom"
                    trigger={
                      <Button
                        variant="outline"
                        size="toggle"
                        onClick={unsnooze}
                      >
                        <AlarmClockOff width={18} height={18} />
                      </Button>
                    }
                    content={
                      <p className="font-medium text-black">
                        Unsnooze conversation
                      </p>
                    }
                  />
                ) : (
                  <Tooltip
                    disableHoverableContent
                    triggerAsChild
                    side="bottom"
                    trigger={
                      <Button
                        variant="outline"
                        size="toggle"
                        onClick={() => setSnoozeDialogOpen(true)}
                      >
                        <AlarmClock width={18} height={18} />
                      </Button>
                    }
                    content={
                      <p className="font-medium text-black">
                        Snooze conversation
                      </p>
                    }
                  />
                )}
              </>
            ))}
          {showTakeOver && !conversation.isHandedOff && (
            <Tooltip
              disableHoverableContent
              triggerAsChild
              side="bottom"
              trigger={
                <Button variant="default" size="toggle" onClick={takeOver}>
                  <div className="flex items-center gap-2">
                    <Forward
                      width={16}
                      height={16}
                      className="pb-0.5"
                      strokeWidth={2.5}
                    />
                  </div>
                </Button>
              }
              content={
                <p className="font-medium text-black">Take over conversation</p>
              }
            />
          )}
        </div>
      </div>
    </ConversationTopBar>
  );
}

import {
  Table as BaseTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@dashboard/common/ui/table";
import { Checkbox } from "@dashboard/common/ui/checkbox";

import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { DeleteRecordDialog } from "./components/DeleteRecordDialog";
import { RecordActions } from "./components/RecordActions";
import { AddRecordDialog } from "./components/AddRecordDialog";
import { SearchBar } from "./components/SearchBar";
import { Button } from "../ui/button";

interface Column<Record extends RecordWithId> {
  header: string;
  accessor: keyof Record;
  headerClassName?: string;
  cellClassName?: string;
}

export interface RecordWithId {
  id: string;
}

interface TableProps<Record extends RecordWithId> {
  title?: string;
  records: Record[];
  columns: Column<Record>[];
  addRecordDialogContent?: (
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => ReactNode;
  editRecordDialogContent?: (
    record: Record,
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => ReactNode;
  deleteRecords?: (ids: string[]) => void;
  headerActions?: { label: string; action: (ids: string[]) => void }[];
  contextActions?: { label: string; action: (ids: string[]) => void }[];
  recordName?: { singular: string; plural: string };
  noRecordsText?: string;
  search?: {
    setSearch: (value: string | undefined) => void;
    searchQuery: string | undefined;
  };
}

export function Table<Record extends RecordWithId>({
  title,
  records,
  columns,
  addRecordDialogContent,
  editRecordDialogContent,
  deleteRecords,
  headerActions,
  contextActions,
  recordName = { singular: "Record", plural: "Records" },
  noRecordsText = "No records available",
  search,
}: TableProps<Record>) {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const actionsEnabled = Boolean(deleteRecords ?? editRecordDialogContent);

  const handleSelectAll = (checked: boolean | string) => {
    if (checked) {
      setSelectedIds(records.map((record) => record.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelect = (id: string, checked: boolean | string) => {
    if (checked) {
      setSelectedIds((ids) => [...ids, id]);
    } else {
      setSelectedIds((ids) => ids.filter((selectedId) => selectedId !== id));
    }
  };

  return (
    <div className="flex flex-col pb-10">
      {/* Table top bar */}
      {title && (
        <div className="border-gray-light flex w-full flex-row justify-between border-b px-10 py-4">
          <h1 className="text-xl font-medium">{title}</h1>
          {addRecordDialogContent && (
            <AddRecordDialog recordNameSingular={recordName.singular}>
              {addRecordDialogContent}
            </AddRecordDialog>
          )}
        </div>
      )}

      <div className="mx-auto flex w-full flex-col px-10">
        {/* Table search bar */}
        {/* TODO: Add search functionality */}
        {search && (
          <>
            <SearchBar search={search} />
            <div className="bg-gray-light h-[1px] w-full" />
          </>
        )}

        {/* Table tool bar */}
        <div className="flex h-14 flex-row items-center justify-start gap-x-5 pt-5">
          {selectedIds.length > 0 ? (
            <>
              <h2 className="text-lg text-black">
                {`${selectedIds.length} ${
                  selectedIds.length === 1
                    ? recordName.singular
                    : recordName.plural
                } selected`}
                <span className="text-gray"> of {records.length}</span>
              </h2>

              {deleteRecords && (
                <DeleteRecordDialog
                  ids={selectedIds}
                  recordName={recordName}
                  setSelectedIds={setSelectedIds}
                  deleteRecords={deleteRecords}
                />
              )}

              {headerActions?.map(({ label, action }) => (
                <Button
                  key={label}
                  onClick={() => action(selectedIds)}
                  variant="secondary"
                >
                  {label}
                </Button>
              ))}
            </>
          ) : (
            <h2 className="text-medium text-lg text-black">
              {`${records.length} ${
                records.length === 1 ? recordName.singular : recordName.plural
              }`}
            </h2>
          )}
        </div>

        {/* Table */}
        <BaseTable>
          <TableHeader>
            <TableRow className="h-12 hover:bg-transparent">
              <TableHead className="w-16 first:pl-0">
                <Checkbox
                  onCheckedChange={(checked) => handleSelectAll(checked)}
                />
              </TableHead>
              {columns.map((column) => (
                <TableHead
                  key={column.accessor as string}
                  className={`${column.headerClassName}`}
                >
                  {column.header}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {records.length === 0 ? (
            <TableCell colSpan={columns.length + 2}>
              <div className="flex items-center justify-center py-16">
                {noRecordsText}
              </div>
            </TableCell>
          ) : (
            <TableBody>
              {records.map((record) => (
                <TableRow key={record.id} className="h-12 hover:bg-transparent">
                  <TableCell className="first:pl-0">
                    <Checkbox
                      checked={selectedIds.includes(record.id)}
                      onCheckedChange={(checked) =>
                        handleSelect(record.id, checked)
                      }
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.accessor as string}
                      className={column.cellClassName}
                    >
                      {record[column.accessor] as ReactNode}
                    </TableCell>
                  ))}
                  {actionsEnabled && (
                    <TableCell className="text-right">
                      <RecordActions
                        id={record.id}
                        record={record}
                        recordName={recordName}
                        setSelectedIds={setSelectedIds}
                        deleteRecords={deleteRecords}
                        editRecordDialogContent={editRecordDialogContent}
                        contextActions={contextActions}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </BaseTable>
      </div>
    </div>
  );
}
